@import url(https://fonts.googleapis.com/css?family=Bungee);

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif; */
    font-family: Montserrat, Roboto, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.hide-hubspot #hubspot-messages-iframe-container {
    visibility: hidden;
}

body.position-left #hubspot-messages-iframe-container {
    left: 56px;
}

@media only screen and (min-width: 1280px) {
    body.position-left #hubspot-messages-iframe-container {
        left: 56px;
    }

    body.position-left.expanded #hubspot-messages-iframe-container {
        left: 240px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.truck {
    height: 50%;
    /* width: 50%; */
}

.sticky-footer-margin {
    margin-bottom: 80px;
}

.firebase-emulator-warning {
    display: none;
}

.planning-marker-tags-label {
    border: 1px solid black;
    background-color: white;
    color: black;
    width: 100px;
    white-space: wrap;
    position: absolute;
    left: -50px;
    top: 50px;
}
