@import url(https://fonts.googleapis.com/css?family=Bungee);
@import url('https://fonts.googleapis.com/css?family=Exo');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
    text-align: center;
    background-color: #5a2fc2;
}
.shipstuff {
    margin-top: 2%;
}
.shipstuff2 {
    margin-top: 3%;
}

.logo {
    font-size: 64px;
    font-weight: 800;
    color: #71b77a;
    font-family: 'Karla';
}

legend {
    float: unset;
}

body {
    background-color: #f0f0f0 !important;
}

.gray-background {
    background-color: #f0f0f0;
}

.gray-text {
    color: #8d8d8d;
}

.bg-gray {
    background-color: #8d8d8d;
}

.stepfour {
    text-align: center !important;
    font-size: 12px;
    margin-bottom: 1px;
    margin-left: 30%;
    width: 40%;
    /* align-items: "center"; */
    /* background-color: #5a2fc2; */
}
/* .custTop {
  display: none;
} */
.textmargin {
    margin-top: 3% !important;
}
.formlabel {
    text-align: bottom;
    padding: 0.375rem 0.75rem;
    font-weight: 700px;
    width: 180px;
}
.photomargin {
    margin-left: 1%;
}
.photoformlabel {
    text-align: bottom;
    padding: 0.375rem 0.75rem;
    font-weight: 700px;
    width: 250px;
}
.formlabelstairs {
    text-align: bottom;
    padding: 0rem 0.75rem 0.375rem 0.75rem;
    font-weight: 700px;
    width: 380px;
}
.formlabelstairsquant {
    text-align: bottom;
    padding: 0.375rem 0.75rem;
    font-weight: 700px;
    width: 300px;
}
.formstatezip {
    text-align: bottom;
    padding: 0.375rem 0.75rem;
    font-weight: 700px;
    width: 80px;
}
.newform {
    width: 60%;
    height: 45px;
    border: solid;
    border-width: medium;
    border-radius: unset;
}
.newformnum {
    width: 35%;
    border: solid;
    border-width: medium;
    border-radius: unset;
}
.formmargin {
    margin-left: 15% !important;
    margin-right: 15% !important;
}
.covcolmargin {
    margin-left: 12.5% !important;
    margin-right: 12.5% !important;
    margin-top: 3% !important;
    column-span: 9 !important;
}
.covcolmarginacct {
    margin-left: 1.5% !important;
    margin-right: 1.5% !important;
    margin-top: 3% !important;
    column-span: 9 !important;
}
.covidform {
    width: '70%' !important;
    margin-left: '15%' !important;
    border: 'none' !important;
    border-bottom: 'solid' !important;
    border-bottom-width: 'thin' !important;
    border-radius: 'unset' !important;
}
.custtrack {
    margin-top: 10%;
    text-align: center;
}

.contactMargin {
    margin-top: 80px;
}

.clickable {
    cursor: pointer;
    color: black !important;
    white-space: pre;
}

.tableWrap {
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contactalign {
    display: block !important;
    margin-left: 9%;
    margin-top: 10%;
    /* text-align: center; */
}
.whyrecomp {
    background-color: #5a2fc2;
    color: white !important;
    margin-bottom: 0% !important;
    text-align: justify !important;
    padding: 10% !important;
}
.blurstuff {
    /* backdrop-filter: blur(10px); */
    filter: blur(6px);
}
.recompinvite {
    background-color: #5a2fc2 !important;
    color: white !important;
    margin-bottom: 0% !important;
    padding: 10% !important;
}
.whytext {
    margin-left: 2% !important;
}
.whytext1 {
    margin-left: 2% !important;
}
.whybody {
    margin-left: 5% !important;
    margin-top: 1.5% !important;
}

.App-logo {
    animation: none;

    pointer-events: none;
    align-content: center;
    align-self: center;
    float: center;
}

.App-header {
    /* background-color: white; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 150;
    color: #5a2fc2;
}

.App-link {
    color: #122227;
}

.Admin-label-width {
    width: 185px;
}

.rounded {
    border-radius: 150px;
    background-color: #5a2fc2 !important;
    color: white;
    margin: 5px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.homefont {
    font-family: 'Circular', 'Open Sans', Arial, Helvetica, sans-serif;
    margin-left: 0px;
    margin-top: 10px;
    margin-right: 0px;
    font-size: 64 !important;
    size: 100;
    font-weight: 900;
    line-height: 1.1;
    text-align: center;
    /* color: white; */
}
.covidfont {
    font-family: 'Circular', 'Open Sans', Arial, Helvetica, sans-serif;
    margin-left: 10%;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 10%;
    font-size: 28px !important;
    size: 120;
    font-weight: 900;
    line-height: 1.1;
    /* color: white; */
}
.covidfontdeus {
    font-family: 'Circular', 'Open Sans', Arial, Helvetica, sans-serif;
    margin-left: 10%;
    margin-top: 20px;
    margin-right: 10%;
    font-size: 64 !important;
    size: 100;
    font-weight: 500;
    line-height: 1.1;
    /* color: white; */
}
.container-style {
    background-color: #5a2fc2;
}
.colorover {
    background-color: white !important;
}
.onwardGreen {
    color: #71b77a;
}
.onwardGreenButton {
    background-color: #71b77a;
    border: 0;
}
.titlefont {
    font-family: 'Bungee';
    color: #5a2fc2;
    text-align: center;
    float: center !important;
    align-self: center !important;
    justify-content: left !important;
    font-size: 72 !important;
}
.navbar .navbar-nav {
    display: flex;
    float: none;
    vertical-align: top;
    align-content: center;
    justify-content: center;
    flex-direction: row !important;
}

.navbar .navbar-collapse {
    text-align: center;
}

.subfont {
    font-family: 'Open Sans', 'Circular', Arial, Helvetica, sans-serif;
    font-size: 34 !important;
    line-height: 1.5;
    font-weight: 400;
    margin: 2%;
    text-align: center;
    /* color: white; */
}
.subfont2 {
    font-family: 'Open Sans', 'Circular', Arial, Helvetica, sans-serif;
    font-size: 34 !important;
    line-height: 1.5;
    font-weight: 400;
    margin: 2%;
    text-align: center;
    /* color: white; */
}
.requeststyle {
    background-color: #5a2fc2;
    color: #5a2fc2;
}
.buttonstuff {
    background-color: white !important;
    color: black !important;
}
.imagestyle {
    align-items: center !important;
    margin-bottom: 0px;
    width: 70%;
    height: 70%;
}
.formstyle {
    align-content: center;
    justify-content: center;
    align-items: center;
}
.bottomformstyle {
    align-content: center;
    margin-left: 2% !important;
    margin-top: 2% !important;
}

.navbar-size-new {
    font-size: 72 !important;
}
h1 {
    font-size: 41px !important;
}

.landingtile {
    font-size: 1.5rem !important;
}
.landingbottomblurb {
    flex: 12;
    color: white;
    font-weight: 800;
    font-size: 40px;
    margin-top: 100px;
}
.secondary_screen {
    background-color: #5a2fc2;
    width: 100%;
}

.navstuff {
    height: 60px;
    padding: 10px;
    background-color: #0c0b1d !important;
    color: white !important;
}
.navstuff2 {
    /* height: 0%; */
    /* padding: 10px; */
    position: absolute;
    background-color: #0c0b1d;
}
.customerProgress {
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 999;
}

.separateFromNav {
    margin-top: 60px;
    width: 100%;
}

.scrollable-grid {
    overflow: auto;
    height: 93vh;
}

.navmore {
    color: white !important;
    /* height: 0%; */
    margin-left: 50px;
    margin-right: 50px;
    font-family: 'Exo', sans-serif;
    /* position: absolute; */
    /* background-color: #051e34; */
}
.navmore1 {
    /* color: white !important; */
    /* height: 0%; */
    margin-left: 80px;
    margin-right: 15px;
    /* text-align: right; */
    /* position: absolute; */
    /* background-color: #051e34; */
}
.navmore2 {
    color: black !important;
    background-color: white;
    border-radius: 25px;
    padding: 5px;
    margin-top: 5px;
    font-size: 0.9rem;
    /* height: 0%; */
    /* margin-left: 80px; */
    /* text-align: right; */
    /* position: absolute; */
    /* background-color: #051e34; */
}
.navmore3 {
    color: black !important;
    /* height: 0%; */
    /* margin-left: 80px; */
    /* text-align: right; */
    /* position: absolute; */
    /* background-color: #051e34; */
}
.navmore4 {
    color: white !important;
    background-color: #0c0b1d;
    border-radius: 25px;
    border: solid;
    border-width: thin;
    padding: 5px;
    margin-top: 5px;
    font-size: 0.9rem;
}
.navmoreOnwardGreen {
    background-color: #71b77a;
    color: black !important;
    border-radius: 25px;
    padding: 5px;
    margin-top: 5px;
    font-size: 0.9rem;
    margin-left: 80px;
    margin-right: 15px;
    border: solid;
    border-width: thin;
}

/* .navspacing {
    margin: 2%;
} */
.accountwidth {
    width: 100% !important;
}
.imgpop {
    margin-left: 45% !important;
    margin-top: 0px !important;
}
.navfont {
    font-family: 'Bungee';
    margin-left: 5rem;
}
.exo {
    font-family: 'Exo', sans-serif;
}
.frontimg {
    background-image: url('../assets/owtruck1.webp');
    height: 550px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
}
.homeimg {
    background-image: url('../assets/onwardtruck2.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.homeimg2 {
    background-image: url('../assets/owtruck3.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.homeimg3 {
    background-image: url('../assets/owtruck4.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.homeimg4 {
    background-image: url('../assets/owtruck8.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.homeimg5 {
    background-image: url('../assets/owtruck6.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.homeimg6 {
    background-image: url('../assets/owtruck7.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}

.homeimg7 {
    background-image: url('../assets/owtruck10.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}

.homeimg8 {
    background-image: url('../assets/onwardlogotransparent.webp');
    /* background-image: url("../assets/onwardlogowhite.webp"); <-- OLD LOGO */
    height: 50px;
    position: relative;
    background-position: center;
    width: 140px;
    background-size: cover;
}

.shipperTopImg {
    background-image: url('../assets/ShipperCoverpage.webp');
    height: 550px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.errorBoundaryImg {
    background-image: url('../assets/errorBoundaryImg.png');
    height: 400px;
    position: relative;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.carrierSignedUp {
    background-image: url('../assets/CarrierSignedUp.png');
    height: 400px;
    position: relative;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.carrierCoverImg {
    background-image: url('../assets/carrierCover.webp');
    height: 550px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
}
.landingCoverImg {
    background-image: url('../assets/landingCover.webp');
    height: 550px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
}
.saveMoneyImg {
    background-image: url('../assets/SaveTimeSaveMoney.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.safeReliableImg {
    background-image: url('../assets/SafeAndReliable.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.expandCoverageImg {
    background-image: url('../assets/ExpandCoverage.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.theGoalIsGreenImg {
    background-image: url('../assets/owtruck8.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.welcomeCommunityImg {
    background-image: url('../assets/welcomeCommunity.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.findLoadsImg {
    background-image: url('../assets/findLoads.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.realLoadsImg {
    background-image: url('../assets/realLoads.webp');
    height: 350px;
    position: relative;
    background-position: center;
    width: 100%;
    background-size: cover;
}
.homebutton {
    /* position: absolute; */
    /* bottom:0; */
    margin-left: 9%;
    font-size: 1rem;
    /* color: #fff; */
    text-align: center;
}
.cancelpage {
    /* position: absolute; */
    /* bottom:0; */
    margin-left: 9%;
    font-size: 1rem;
    /* color: #fff; */
    /* text-align: center; */
}
.landhomebutton {
    /* position: absolute; */
    /* bottom:0; */
    margin-left: 9%;
    font-size: 1rem;
    /* color: #fff; */
    text-align: center;
}
.homebut {
    margin: 5px;
    /* margin-left: 5%; */
    background-color: #f65335;
    border-radius: 20px;
    font-size: 1.8rem;
    line-height: 2.5rem;
    width: 9.5rem;
    text-align: center;
}
.bottombutton {
    text-align: center;
    justify-content: center;
    background-color: #0c0b1d !important;
    height: 350px !important;
}
.bottombuttonnew {
    text-align: center;
    justify-content: center;
    /* background-color: #051e34 !important; */
    /* height: 350px !important; */
}
.bottombut {
    /* margin: 5px; */
    /* margin-left: 5%; */
    background-color: #f65335;
    border-radius: 20px;
    font-size: 1.8rem;
    line-height: 2.5rem;
    width: 12.5rem;
    text-align: center;
}
.buttontext {
    margin: 0px 5px 0px;
    /* margin-left: 5%; */
    /* background-color: #f65335;     */
    /* border-radius: 20px; */
    font-size: 0.7em;
    color: white;
    font-weight: 600;
    text-align: center;
    /* line-height: 2rem; */
}
.CTAButton {
    /* margin: 5px; */
    /* margin-left: 5%; */
    background-color: #71b77a;
    color: white;
    font-weight: 600;
    font-size: 1.3rem;
    border-radius: 20px;
    line-height: 2.5rem;
    width: 12.5rem;
    text-align: center;
}
a.CTAButton {
    color: white;
    text-decoration: none;
    font-family: 'Exo', sans-serif;
}
.subbutton {
    margin: 25px;
    background-color: black;
    border-radius: 14px;
}
.signin {
    text-align: center;
    margin: 10%;
}
.signinform {
    margin-bottom: 10%;
    background-color: #fff;
    padding-bottom: 70px;
    padding-right: 70px;
    padding-left: 70px;
    padding-top: 70px;
    border-radius: 10px;
    margin-top: 5%;
    /* border: 'thin solid'; */
}
.custtrackform {
    margin-bottom: 10%;
    background-color: #fff;
    padding-bottom: 70px;
    margin-top: 15%;
    /* padding-right: 70px;
  padding-left: 70px;
  padding-top: 70px; */
    width: 50%;
    margin-left: 25%;
    border-radius: 10px;
    margin-top: 5%;
    /* border: 'thin solid'; */
}
.signupform {
    margin-bottom: 10%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 2%;
    /* border: 'thin solid'; */
}
.carrsignupform {
    margin-bottom: 10%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-top: 2%;
    width: 50%;
    justify-content: center;
    /* border: 'thin solid'; */
}
.carrsignupformtres {
    margin-bottom: 10%;
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    margin-top: 2%;
    width: 75%;
    justify-content: center;
    /* border: 'thin solid'; */
}
.signup {
    text-align: center;
    margin: 10%;
}

/* .navspacing {
    margin: 15px;
} */
.customnav {
    align-items: right;
}

.sidebarmargin {
    margin-left: 250px;
}

.navtext {
    font-weight: 700;
}

.navLinkWhite {
    color: white;
}

.myOrdersInstructions {
    overflow: visible;
    white-space: nowrap;
    width: 50px;
}

.navhead {
    font-family: 'Bungee';
    text-align: center;
    margin: 10%;
}
.acctspacing {
    /* margin-left: 25%; */
    text-align: center;
}
.homeblurb {
    margin-left: 10%;
    margin-top: 15%;
    position: relative;
    /* margin-bottom: 30px; */
    /* background-color: 'red'; */
    /* text-align: right; */
}
.homeblurbtext {
    color: white;
    font-weight: 600 !important;
    font-family: 'rift', sans-serif;
    font-size: 3rem;
    line-height: 3rem;
    display: block;
    margin-bottom: 10px;
}
.contactblurbtext {
    color: black;
    font-weight: 800 !important;
    font-family: 'rift', sans-serif;
    font-size: 3rem;
    line-height: 3rem;
    display: block;
    margin-bottom: 10px;
}

.cardstyles {
    height: 250px;
    color: black;
    text-align: center;
}

.text-larger {
    font-size: 3em !important;
}

.map-container {
    height: 100%;
    width: 100%;
    border: solid 1px rgb(200, 200, 200);
    border-radius: 5px;
    box-shadow: 1px 1px 7px 3px rgba(137, 137, 137, 0.23);
}

@media (max-width: 1199px) {
    .custtrackform {
        margin-bottom: 10%;
        background-color: #fff;
        padding-bottom: 70px;
        /* padding-right: 70px;
    padding-left: 70px;
    padding-top: 70px; */
        width: 95%;
        margin-left: 2.5%;
        border-radius: 10px;
        margin-top: 5%;
        /* border: 'thin solid'; */
    }
    .accountwidth {
        width: 98% !important;
    }
    .custtrack {
        margin-top: 15%;
        text-align: center;
    }
    .bottombutton {
        text-align: center;
        justify-content: center;
        background-color: #0c0b1d !important;
        height: 250px !important;
    }
    .bottombuttonnew {
        text-align: center;
        justify-content: center;
        /* background-color: #051e34 !important; */
        /* height: 250px !important; */
    }
    .textmargin {
        margin-top: 5% !important;
    }
    .h1 {
        font-size: 36px !important;
    }
    .landingtile {
        font-size: 1.3rem !important;
    }
    .navmore {
        color: white !important;
        /* height: 0%; */
        margin-left: 0px !important;
        margin-right: 50px;
        font-size: 0.8rem !important;
        /* position: absolute; */
        /* background-color: #051e34; */
    }
    .navfont {
        font-family: 'Bungee';
        margin-left: 1rem !important;
    }
    .blog {
        display: none !important;
    }
    .landingbottomblurb {
        flex: 12;
        color: white;
        font-weight: 800;
        font-size: 25px;
        margin-top: 100px;
    }
}

@media (max-width: 991px) {
    /* .custTop {
    display: "block" !important;
  } */
    .navmoreOnwardGreen {
        background-color: #71b77a;
        color: black !important;
        border-radius: 25px;
        padding: 5px;
        margin-top: 5px;
        font-size: 0.9rem;
        margin-left: 80px;
        margin-right: 80px;
        border: solid;
        border-width: thin;
    }
    .CTAButton {
        background-color: #71b77a;
        color: white;
        font-weight: 600;
        font-size: 1.3rem;
        border-radius: 20px;
        line-height: 2.5rem;
        width: 12.5rem;
        text-align: center;
        margin: 5px;
    }
    .custtrack {
        margin-top: 20%;
        text-align: center;
    }
    .stepfour {
        text-align: left !important;
        font-size: 12px;
        margin-bottom: 1px;
        margin-left: 5% !important;
        width: 100% !important;
        /* align-items: "center"; */
        /* background-color: #5a2fc2; */
    }
    .subtext {
        margin-top: 20px !important;
        text-align: center;
    }
    .subtextbody {
        font-size: 1rem !important;
        margin: 5px;
        margin-bottom: 10px !important;
    }
    .footersmall {
        display: none;
    }
    .bottombutton {
        text-align: center;
        justify-content: center;
        background-color: #0c0b1d !important;
        height: 250px !important;
    }
    .bottombuttonnew {
        text-align: center;
        justify-content: center;
        /* background-color: #051e34 !important; */
        /* height: 250px !important; */
    }
    .h1 {
        font-size: 36px !important;
    }

    .textmargin {
        margin-top: 10% !important;
    }
    .imagestyle {
        margin-top: 5% !important;
    }
    h1 {
        font-size: 29px !important;
    }
    .navfont {
        font-family: 'Bungee';
        margin-left: 1rem !important;
    }
    .homeblurb {
        margin-left: 10%;
        margin-top: 15%;
        position: relative;
        /* margin-bottom: 30px; */
        /* background-color: 'red'; */
        /* text-align: right; */
    }
    .homeblurbtext {
        color: white;
        font-weight: 600 !important;
        font-family: 'rift', sans-serif;
        font-size: 2rem !important;
        line-height: 2rem !important;
        display: block;
        margin-bottom: 10px;
    }
    .landingbottomblurb {
        flex: 12;
        color: white;
        font-weight: 800;
        font-size: 20px;
        margin-top: 50px;
        padding-left: 10px;
        padding-right: 15px;
        /* margin-left: 5px;
    margin-right: 5px; */
    }
}

@media (max-width: 768px) {
    .cardstyles {
        height: 150px;
        color: black;
        text-align: center;
    }
    .cardpadding {
        padding-top: 1.25rem !important;
    }
    h1 {
        font-size: 24px !important;
    }
    h5 {
        font-size: 1rem !important;
    }
    .subfont {
        font-family: 'Circular', 'Open Sans', Arial, Helvetica, sans-serif;
        font-size: 34 !important;
        line-height: 1.5;
        font-weight: 400;
        margin: 2%;
        text-align: center;
        /* color: white; */
    }
    .subfont2 {
        font-family: 'Circular', 'Open Sans', Arial, Helvetica, sans-serif;
        font-size: 14px !important;
        line-height: 1.5;
        font-weight: 500;
        margin: 3%;
        text-align: center;
        /* color: white; */
    }
    .navstuff {
        height: 60px;
        padding: 10px;
        background-color: #0c0b1d !important;
        color: white !important;
        display: 'none';
    }
    .navmore4 {
        color: white !important;
        background-color: #0c0b1d;
        border-radius: 25px;
        border: solid;
        border-width: thin;
        padding: 5px;
        margin-top: 5px;
        font-size: 0.9rem;
        margin-left: 85px;
        margin-right: 85px;
    }
    .navmore1 {
        /* color: white !important; */
        /* height: 0%; */
        margin-left: 85px;
        margin-right: 85px;
        /* text-align: right; */
        /* position: absolute; */
        /* background-color: #051e34; */
    }
    .navmore5 {
        /* color: white !important; */
        /* height: 0%; */
        /* margin-left: 10px;
    margin-right: 10px; */
        text-align: center;
        /* text-align: right; */
        /* position: absolute; */
        /* background-color: #051e34; */
    }
    .navmore6 {
        /* color: white !important; */
        /* height: 0%; */
        /* margin-left: 85px;
    margin-right: 85px; */
        display: none;
        /* text-align: right; */
        /* position: absolute; */
        /* background-color: #051e34; */
    }
    .navmore {
        color: white !important;
        /* height: 0%; */
        width: 100%;
        margin-left: 50px;
        margin-right: 50px;
        background-color: #0c0b1d !important;
        /* flex-direction: column !important; */
        /* position: absolute; */
        /* background-color: #051e34; */
    }
    .navbar .navbar-nav {
        flex-direction: column !important;
    }
    .homefont {
        font-family: 'Circular', 'Open Sans', Arial, Helvetica, sans-serif;
        margin-left: 0px;
        margin-top: 50px;
        margin-right: 0px;
        font-size: 64 !important;
        size: 100;
        font-weight: 900;
        line-height: 1.1;
        /* color: white; */
    }

    .frontimg {
        background-image: url('../assets/owtruck1.webp');
        background: rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) !important;
        height: 500px !important;
        position: relative !important;
        width: 100%;
        background-size: cover;
        background-position: bottom !important;
    }
    .navfont {
        font-family: 'Bungee';
        margin-left: 1rem !important;
    }
    .homeblurb {
        margin-left: 10%;
        margin-top: 20% !important;
        position: relative;
        /* margin-bottom: 30px; */
        /* background-color: 'red'; */
        /* text-align: right; */
    }
    .homeblurbtext {
        color: white;
        font-weight: 600 !important;
        font-family: 'rift', sans-serif;
        font-size: 2rem !important;
        line-height: 2rem !important;
        display: block;
        margin-bottom: 10px;
    }

    .imagestyle {
        margin: 5px;
        align-items: center;
        width: 100%;
        justify-content: flex-end !important;
    }
    .textmargin {
        margin-top: 5% !important;
    }
    .whyrecomp {
        background-color: #5a2fc2;
        /* color: white !important; */
        margin-bottom: 0% !important;
        padding: 5% !important;
    }
    .formmargin {
        margin-left: 0% !important;
        margin-right: 0% !important;
    }

    .whytext {
        margin-left: 2% !important;
        margin-bottom: 5% !important;
    }
    .whytext1 {
        margin-left: 2% !important;
        margin-bottom: 5% !important;
        text-align: left !important;
        font-size: 20px !important;
    }
    .whybody {
        margin-left: 5% !important;
        margin-top: 3.5% !important;
        font-size: 18px !important;
        text-align: left !important;
    }
    .covcolmargin {
        margin-left: 5% !important;
        margin-right: 5% !important;
        margin-top: 1% !important;
        column-span: 9 !important;
    }
}
