.Admin-label-width{
    width: 160px !important;
}

.flex-1{
    flex: 1;
}

.invalid-feedback{
    padding-left: 160px;
}

.error-message{
    color: red;
}