* {
    font-family: "Montserrat", "Roboto", "Arial" !important;
}

a.tabLink:link{
    color: rgb(156, 156, 156);
}

.tabLink:hover {
    color: #59B863;
}

.tabCard{
    border-top: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: #71b77a;
    border-color: rgb(187, 187, 187);
}

.password-height{
    height: 2.5em;
}

.hours-table{
    width: 75%;
}

@media (max-width: 1300px) {
    .hours-table{
        width: auto;
    }
}

button {
    white-space: nowrap;
}

.account-btn {
    background: #59B863 !important;
    color: white !important;
    text-transform: none;
    font-weight: 700;
}

.nav {
    --bs-nav-link-color: black;
}