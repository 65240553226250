.item-qty-text {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #8d8d8d;
    margin-left: 20px !important;
}

.items-card {
    width: 100%;
    border-radius: 1 !important;
    border-bottom: 1px solid #e2e2e2;
}

.item-delivery-photos-container {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 10px;
}

.items-delivery-photos {
    margin-left: 10px;
}
