.DayPicker-Day {
    position: relative;
    width: 14%;
    height: 0;
    padding-bottom: 5% !important;
    padding-top: 5% !important;
}

.DayPicker {
    flex-direction: row;
    font-size: auto;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.DayPicker-Month {
    display: table;
    margin: 0;
    padding: 0;
    margin-top: auto;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;
}
