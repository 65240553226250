.rating {
    text-align: left !important;
}

.rating-stars {
    margin-left: -4px;
}


/*
   These need to be namespaced so they're not
   affecting other Mui components around the app.

.MuiDialogActions {
    padding: 80px 240px 240px !important;
}

.MuiPaper-root {
    width: 640px;
}
*/

.title {
    font-size: 1.55rem !important;
    font-weight: bold !important;
}

.order-summary .text-green {
  color: #59b863;
}

.order-summary .text-orange {
  color: #E28700;
}

.order-summary .text-red {
  color: #D23E3E;
}

.order-summary .under-decoration {
  text-decoration: underline;
}

.order-summary .stops-count {
  color: #999
}

.exception-modal-header{
  font-size: 28px !important;
  font-weight: 800 !important;
}

.exceptions-modal-container{
  padding: 1rem;
  min-width: 60vw;
  min-height: 60vh;
}

.exception-source{
  font-size: 18px !important;
  font-weight: 800 !important;
  margin-bottom: 20px !important;
}

.exception-item-desc{
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
}

.exception-text{
  font-size: 16px !important;
  margin-bottom: 10px !important;
}

.tracking-modal-spacing{
  margin-left: calc(56px - 313px + (100vw - 56px) / 2);
  padding: 10px 15px;
  padding-bottom: 88px !important;
  width: 626px;
}

.small-modal{
  width: 100%;
  display: none;
}

@media only screen and (min-width: 577px) {
    .tracking-modal-spacing {
        margin: 10rem auto 0 auto;
    }
}

@media only screen and (max-width: 576px) {
  .tracking-modal-spacing {
    width: 100vw;
    padding: 0;
    margin: 0;
    margin-top: -25px;
    display: none;
  }

  .exceptions-modal > div{
    display: none;
  }

  .exceptions-modal {
    display: none;
  }

  .exceptions-modal-paper{
    border-radius: 0px !important;
    height: 100vh;
  }

  .exception-images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .modal-open-class{
    display: none !important;
  }

  .small-modal{
    display: block;
  }

}
.MuiStepLabel-label.Mui-completed {
  position: relative;
}

#react-modal-image-img {
  width: 100%;
}
