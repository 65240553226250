#chips-display {
  background-color: rgb(225, 225, 225);
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgb(77, 77, 77);
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-height: 350px;
}

#chips-display>p {
  color: grey;
  font-weight: bold;
}

.autocomplete-item:hover {
  cursor: pointer;
}

.chip {
  background-color: rgb(94, 94, 94);
  color: white;
  padding: 3px 8px;
  margin: 0 2px;
  margin-top: 3px;
  border-radius: 3px;
  align-items: center;
  align-content: center;
}

.chip-close-icon:hover {
  cursor: pointer;
}