.search-input-container {
    display: flex;
    justify-content: center;
    align-self: end;
    align-items: end;
    text-align: center;
}

.admin-ftl-header {
    display: flex;
    align-items: end;
    justify-content: center;
}

.admin-ftl-header > h3 {
    align-self: center;
    display: flex;
    align-items: end;
}

.filters-button {
    height: 35px;
    margin: 2.5px !important;
    align-self: center;
}
.green-button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

#delivery-info-selectors {
    display: flex;
}

.ftl-filters-header {
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #f7f7f7;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 1px solid #ebebeb;
}

.ftl-fitlers-subheader {
    font-weight: 500;
    text-align: center;
    font-size: 20px;
    padding-top: 10px;
}

#state-selector {
    text-align: center;
    padding: 0px 0px 12px 0px;
}
